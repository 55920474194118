// @ts-check

import VueInstance from '@/main'

/**
 * @typedef {import('vue-i18n').TranslateResult} TranslateResult
 * @typedef {import('./action-manager').ActionHandler} ActionHandler
 * @typedef {import('./inc-exc').default} IncExc
 * @typedef {import('./item-list').Item} Item
 * @typedef {import('./item-list').RawItem} RawItem
 * @typedef {import('./item-list').DocumentId} DocumentId
 * @typedef {import('./scope').default} Scope
 */

/**
 * @callback Excluded
 * @returns {Array.<DocumentId>}
 */
/**
 * @callback Default
 * @returns {boolean|string}
 */

/**
 * @typedef {{
 * documentType: string,
 * excluded?: Excluded
 * default?: Default
 * filters?: Object.<string, IncExc>,
 * hasEmail?: boolean,
 * isRequired: boolean,
 * label: TranslateResult,
 * min: string,
 * placeholder?: TranslateResult,
 * scope?: ScopeCallback
 * selectValues?: Array.<SelectValue>
 * type: 'boolean'|'date'|'document'|'select'|'text'|'label'
 * counter: Number
 * }} AcceptedValue
 **/

/**
 * Create an action that can be use in bulk or quick action
 */
export default class Action {
  /** @type {Array.<AcceptedValue>} */
  #acceptedValues = []
  #action
  #code
  #description
  #canBeQuick = true
  #canOnlyBeQuick = false
  #category = Action.category.GENERIC
  #icon
  #isRulesInclusives = false
  #skipConfirmation = false
  #name
  /** @type {ActionHandler} */
  #revert
  /** @type {Array.<Rule>} */
  #rules = []
  #selectionMustBeCleared = false

  /**
   * Create a new action
   * @param {string} code Action code for reference and testing purpose
   * @param {ActionHandler} handler Action handler to execute
   * @param {TranslateResult} name Action name to display
   * @param {TranslateResult} description Action description to display
   * @param {string} [icon] Icon to display for the action
   */
  constructor(code, handler, name, description, icon) {
    this.#code = code
    this.#action = handler
    this.#description = description
    this.#icon = icon
    this.#name = name
  }

  /**
   * @callback ScopeCallback
   * @returns {Scope}
   */

  /**
   * @typedef {{
   * hasEmail?: boolean,
   * isRequired?: boolean,
   * filters?: Object.<string, IncExc>,
   * documentType: 'account-contacts'|'contact-groups'|'accounts'|'business-divisions'|'collection-segments'|'escalation-protocols'
   *              |'processes'|'templates'|'transaction-types'|'users'|'work-item-priorities'
   *              |'work-queues',
   * scope?: ScopeCallback
   * }} AcceptedValueDocType
   */
  /**
  * @typedef {{
  * text: TranslateResult,
  * value: *
  * }} SelectValue
  */
  /**
   * @typedef {{
   * type: 'boolean'|'date'|'select'|'text'|'label'|AcceptedValueDocType,
   * default?: Default,
   * excluded?: Excluded,
   * filters?: Object.<string, IncExc>,
   * label: TranslateResult,
   * min?: string,
   * placeholder?: TranslateResult,
   * values?: Array.<SelectValue>
   * counter?: Number
   * }} AcceptedValueParams
   */
  /**
   * Add a value to be set by the user and used as a parameter of the action during execution
   * Multiple calls to addAcceptValue set multiple values to prompt, ordered by the calls order.
   * @param {AcceptedValueParams} params
   * @returns {this}
   */
  addAcceptedValue (params) {
    this.#canBeQuick = false
    this.#canOnlyBeQuick = false
    /** @type {AcceptedValue} */
    const value = {
      documentType: undefined,
      excluded: () => undefined,
      filters: undefined,
      hasEmail: undefined,
      isRequired: false,
      label: params.label,
      min: params.min,
      placeholder: params.placeholder,
      scope: () => undefined,
      type: undefined,
      counter: params.counter
    }
    switch (params.type) {
      case 'boolean':
        value.type = params.type
        value.default = params.default ?? (() => false)
        break
      case 'date':
        value.type = params.type
        value.default = params.default ?? (() => undefined)
        break
      case 'select':
        value.type = params.type
        value.default = params.default ?? (() => params.values[0].value)
        value.selectValues = params.values
        break
      case 'text':
        value.type = params.type
        value.default = params.default ?? (() => undefined)
        break
      case 'label':
        value.type = params.type
        value.default = params.default ?? (() => undefined)
        break
      default:
        value.type = 'document'
        value.default = params.default ?? (() => null)
        value.documentType = params.type.documentType
        value.excluded = params.excluded ?? (() => undefined)
        value.filters = params.type.filters
        value.isRequired = params.type.isRequired
        value.scope = params.type.scope ?? (() => undefined)
        value.hasEmail = params.type.hasEmail
    }
    this.#acceptedValues.push(value)
    this.#skipConfirmation = false
    return this
  }

  /**
   * @callback Rule
   * @param {RawItem} a
   * @param {RawItem} b
   * @returns {boolean}
   */
  /**
   * Add a rule that every selected items must fulfill for the action to be available.
   * This behavior can be changed by using the rulesAreInclusives method
   * @param {Rule} rule
   * @returns {this}
   */
  addRule (rule) {
    this.#rules.push(rule)
    return this
  }

  /**
   * Prevent an action from being available as a quick action
   * @returns {this}
   */
  cannotBeQuick () {
    this.#canBeQuick = false
    return this
  }

  /**
 * Prevent an action from being available as a bulk action
 * @returns {this}
 */
  cannotBeBulkAction () {
    this.#canOnlyBeQuick = true
    return this
  }

  /**
   * Skip popup confirmation
   * @returns {this}
   */
  skipConfirmation () {
    this.#skipConfirmation = true
    return this
  }

  /**
   * Execute a given action for every selected items
   * @param {Array} values The values to use during execution
   * @returns {Promise<*>}
   */
  execute (values, item) {
    if (process.env.NODE_ENV === 'development') {
      /** Dev checks will be removed during webpack's bundling */
      this.#acceptedValues.forEach((value, index) => {
        switch (value.type) {
          case 'boolean':
            if (typeof values[index] !== 'boolean') {
              throw new Error(`Incorrect execution value for action "${this.#name}", expect ${value.type} but got ${values[index]}`)
            }
            break
          case 'document':
            if (values[index] !== null && typeof values[index] !== 'string') {
              throw new Error(`Incorrect execution value for action "${this.#name}", expect ${value.type} or null for type "${value.documentType}" but got ${values[index]}`)
            }
            break
        }
      })
    }
    return this.#action({ values, item })
  }

  /**
   * Execute a quick action on an item
   * @param {Item} item
   * @param {Function} [cb]
   * @returns {Promise<*>}
   */
  executeQuick (item, cb) {
    if (!item) {
      throw new Error('No item received as target for executing a quick action')
    }

    return this.#action({ item })
      .then(({ isSilent = false } = {}) => {
        // eslint-disable-next-line node/no-callback-literal
        cb?.(false) // False is passed to the callback, indicating this is not a revert action
        let callback
        if (this.canBeReverted) {
          const revertableItem = item
          callback = () => {
            this.revert(revertableItem)
              .then(() => {
                // eslint-disable-next-line node/no-callback-literal
                cb?.(true) // True is passed to the callback, indicating this is a revert action
                VueInstance.$store.dispatch('showWarningSnackbar', VueInstance.$t('t.Canceled'))
              })
              .catch(e => {
                console.error(e)
                VueInstance.$store.dispatch('showErrorSnackbar', VueInstance.$t(e?.response?.data?.message ?? 't.UnknowError'))
              })
          }
        }
        if (!isSilent) {
          VueInstance.$store.dispatch('showSuccessSnackbar', {
            message: VueInstance.$t('t.SaveCompleted'),
            callback,
            callbackMessage: VueInstance.$t('t.Cancel')
          })
        }
        this.isOpen = false
      })
      .catch(e => {
        console.error(e)
        VueInstance.$store.dispatch('showErrorSnackbar', VueInstance.$t(e?.response?.data?.message ?? 't.UnknowError'))
      })
  }

  /**
   * Revert a quick action using the revert handler on an item
   * @param {Item} item
   * @returns {Promise<*>}
   */
  revert (item) {
    if (!item) {
      throw new Error('No item received as target for reverting a quick action')
    }
    return this.#revert({ item })
  }

  /**
   * Mark this action as being available if any selected item match any rule
   * @returns {this}
   */
  rulesAreInclusives () {
    this.#isRulesInclusives = true
    return this
  }

  /**
   * Set a category for grouping when displayed
   * @param {ActionCategory} category
   * @return {this}
   */
  setCategory (category) {
    this.#category = category
    return this
  }

  /**
   * Set a revert action handler that can use in quick action success snackbar
   * @param {ActionHandler} revert A callback
   * @returns {this}
   */
  setRevertAction (revert) {
    this.#revert = revert
    return this
  }

  /**
   * Set the selectionMustBeCleared flag to true
   * @returns {this}
   */
  setSelectionMustBeCleared () {
    this.#selectionMustBeCleared = true
    return this
  }

  /**
   * Return a boolean indicating if the action should be available or not
   * @param {Array.<Item>} items
   * @returns {boolean}
   */
  validateRules (items) {
    const itemsToTest = [...items]
    // We voluntarily kept the referenceItem into itemsToTest. This allow self testing rules.
    const referenceItem = itemsToTest[0]
    return this.#isRulesInclusives
      ? itemsToTest.some(i => this.#rules.some(testRule => testRule(i, referenceItem)))
      : itemsToTest.every(i => this.#rules.every(testRule => testRule(i, referenceItem)))
  }

  get acceptedValues () {
    return this.#acceptedValues
  }

  get action () {
    return this.#action
  }

  get canBeQuick () {
    return this.#canBeQuick
  }

  get canOnlyBeQuick () {
    return this.#canOnlyBeQuick
  }

  get canBeReverted () {
    return Boolean(this.#revert)
  }

  get category () {
    return this.#category
  }

  get code () {
    return this.#code
  }

  get description () {
    return this.#description
  }

  /**
   * Return true if the action has at least one rule and is not in inclusive rules mode
   */
  get hasExcludeRules () {
    return !this.#isRulesInclusives && Boolean(this.#rules.length)
  }

  /**
   * Return true if the action has at least one rule and is in inclusive rules mode
   */
  get hasIncludeRules () {
    return this.#isRulesInclusives && Boolean(this.#rules.length)
  }

  get icon () {
    return this.#icon
  }

  get name () {
    return this.#name
  }

  get mustBeSkipConfirmation () {
    return this.#skipConfirmation
  }

  get selectionMustBeCleared () {
    return this.#selectionMustBeCleared
  }

  /**
   * @typedef {TranslateResult} ActionCategory
   * @enum {ActionCategory}
   */
  static category = {
    ACTIVATION: VueInstance.$t('t.Activation'),
    ASSIGNMENT: VueInstance.$t('t.Assignment'),
    CREATE: VueInstance.$t('t.Create'),
    DSO: VueInstance.$t('t.DSO'),
    DUNNING: VueInstance.$t('t.Dunning'),
    GENERIC: VueInstance.$t('t.Others'),
    EDIT: VueInstance.$t('t.Edit'),
    PORTAL: VueInstance.$t('t.Portal'),
    RISK_MONITORED: VueInstance.$t('t.RiskMonitored'),
    UPDATE_PROPERTY: VueInstance.$t('t.Update')
  }
}
